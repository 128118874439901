* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background: #F2F2F2;
}

/* body, .outer, #root {
  width: 100%;
} */